<template>
    <div class="screen">
        <Nav />
        <div class="screen-inner">
            
            <div class="content">
                <div class="board-wrap">
                    <div class="status card">
                        <div>
                            <p>
                                🚩 {{ data.bombCount }} | 🕒 {{ getTime() }}
                            </p>
                            <div v-if="!this.data.endedAt">
                                <button class="theme-button" @click="giveUp()">Give up</button>
                            </div>
                            
                        </div>
                    </div>
                    <div class="board">
                        <table>
                            <tr v-for="(row, rI) in table" :key="rI">
                                <td v-for="(col, cI) in row" :key="cI" @contextmenu.prevent="rightClick(cI,rI)" @click="leftClick(cI,rI)">
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { giveUp } from '../api';




export default {
    data(){
        return {
            table:[],
            data:null,
            diff:null,
        }
    },
    beforeMount(){
        this.data = this.$route.params.data;
        let table = [];

        let width = this.data.width;
        let height = this.data.height;

        for (let i = 0; i < height; i++) {
            table[i] = (new Array(width)).fill(null).map(()=>{
                return {
                    flagged:false,
                    digged:false,
                }
            });
        }

        this.table = table;

        let setTime = ()=>{
            let started = new Date(this.data.startedAt);
            let now = new Date();
            if (this.data.endedAt){
                now = new Date(this.data.endedAt);
            }
            this.diff = now-started;
        };
        setTime();
        setInterval(setTime,100)
    },
    methods:{
        giveUp(){
            giveUp(localStorage.getItem("accesstoken"),this.data.gameId).then(()=>{
                this.$router.push("/");
            });
        },
        getTime(){
            let s = Math.floor(this.diff/1000);
            let m = Math.floor(s/60);
            s -= m * 60;
            if (s < 10){
                s = "0"+s;
            }
            return `${m}:${s}`;
        },
        rightClick(x,y){
            console.log(x,y);
        },
        leftClick(x,y){
            console.log(x,y);
        }
    }
}
</script>

<style scoped>
.status {
    width: 100%;
    margin: 20px 0;
    box-sizing: border-box;
    padding: 10px;
}
.content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.board-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.board {
    border-radius: 8px;
    overflow: hidden;
    display: inline-block;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.178);
}
.board table {
    border-collapse: collapse;
}
.board td::before{
    content: "\200B";
}
.board td:hover {
    background-color: #419e4c !important;
}
.board td {
    background-color: #72e07f;
    width: 50px;
    height: 50px;
}
.board tr:nth-child(odd) td:nth-child(odd), .board tr:nth-child(even) td:nth-child(even) {
    background-color: #50c45d;
}

</style>